<template>
  
  <div id="Auth" class="auth" style="" v-if="simInfo">
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
  },
  data() {
    return {

    }
  },
  mounted() {
  if(this.simInfo)
  {
    this.init()
  }

    
    
  },
  computed:{
    ...mapGetters({
      simInfo: 'simulator/getSimInfo',
      formLoading: 'utility/getLoginLoading', 
      userData: "user/getMeInfo",
      isLogin: "user/getIsLogin",
      lessons: "simulator/getLessons",
    }),
    
    
  },
  methods:{
    init()
    {
      this.$store.commit("utility/SET_SLUG", this.$route.params.slug)
      if(this.isLogin)
      {
        this.to_new_slug()
        // this.$rooter.push('/courses')
      }
      else{
        this.submitForm()
      }
    },
    async to_new_slug()
    {
      await this.$store.dispatch("simulator/LESSONS_FETCH");
      await this.$store.dispatch('page/SET_PAGE', {
          vm: this,
          page: this.lessons.find(element => element.slug == this.$route.params.slug).user_progress.first_uncompleted_page
      });
      this.$router.push('/')
    },
    async submitForm() {
      this.$store.dispatch('utility/ChangeLoading', true)
      this.$store.commit("utility/SET_LOGIN_LOADING", true)
      var mail = this.RandomString(12)+'@'+this.RandomString(6)+'.'+'ru'
      let pass = 'newpassword'
      console.log(this.$route.params.user)
      if(this.$route.params.user)
      {

        mail = 'test'+this.$route.params.user+'@mysimulator.ru'
      }
      await this.$store.dispatch("user/SIGN_UP", {
        vm: this,
        email: `${mail}`,
        username: `${mail}+${this.simInfo.group}`,
        password: pass,
        group: this.simInfo.group, 
        utm: this.$route.query
      });

      this.$store.dispatch('utility/ChangeLoading', false)
      this.$store.commit("utility/SET_LOGIN_LOADING", false)
    },
    RandomString(length)
    {
      let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    }, 
    
  },
  watch: {
    simInfo() {
      this.init()
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';

.is-not-show{
    display: none;
}

.auth{
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container{
    width: 440px;
    background-color: white;
    z-index: 30;
    box-shadow: 0 14px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 40px;
  }
  &__head{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    &__type{
      font-weight: 700;
      font-size: $mediumTextSize;
    }
    &__close{
      cursor: pointer;
    }
  }
  &__body{
    width: 100%;
  }
  &__change{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: $smallestTextSize;
    color: $primary_color;
    div{
      cursor: pointer;
    }

  }
}
</style>